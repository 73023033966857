html,
body {
  height: 100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  color: #fff;
  font-size: 16px;
  line-height: 1.8em;
  padding-top: 15px;
  background-color: #333;
}

p {
  color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

a {
  color: #fff;
  text-decoration: underline;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover {
  color: #ccc;
}

a:active,
a:visited {
  color: #fff;
}

.fullSection {
  margin-bottom: 20px;
  padding: 25px 0;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

.fullSection--bottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

.portfolio {
  margin-bottom: 150px;
}

.logo {
  margin-bottom: 60px;
  text-align: center;
}

.preview-grid {
  justify-content: center;
  margin-bottom: 20px;
}

.strip {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
}

#about {
  padding: 15px 0;
}

.about .strip {
  padding-top: 25px;
}

.intro {
  margin: 0 0 16px;
  font-size: 18px;
}

@media (min-width: 600px) {
  .intro {
    font-size: 24px;
  }
}

.img-full {
  width: 100%;
}

.img-center {
  margin: 0 auto;
}

.btn-custom {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
}

.btn-custom:hover {
  background: #fff !important;
  border: 2px solid #fff;
  color: #333;
}

.img-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -20px;
}

.img-grid > * {
  flex-basis: calc(100% / 2);
  padding-left: 20px;
  margin-bottom: 20px;
}

@media (min-width: 900px) {
  .img-grid > * {
    flex-basis: calc(100% / 3);
  }
}

@media (min-width: 1300px) {
  .img-grid > * {
    flex-basis: calc(100% / 4);
  }
}

/* ==================================================
	Section Heading
====================================================*/

.section-heading {
  text-align: center;
}

.section-heading--left {
  text-align: left;
}

.section-heading h2 {
  font-size: 40px;
  padding: 10px 0;
  letter-spacing: -1px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* ==================================================
	Icon-Box
====================================================*/

.icon-box {
  padding: 40px 0;
}

.icon-box i {
  font-size: 60px;
}

.icon-box h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

/* ==================================================
	Showcase
====================================================*/

.pic {
  position: relative;
}

a .img_overlay {
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 20px);
  height: 100%;
  background: #333;
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url(../images/zoom.png);
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  border-radius: 3px;
}

a:hover .img_overlay {
  opacity: 1;
  z-index: 1;
}

/* ==================================================
	Modal
====================================================*/

.modal-content {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-header {
  border: none;
}

.modal-header .close {
  font-size: 42px;
  color: #fff;
  opacity: 1;
  font-weight: normal;
}

.modal-body {
  padding: 15px 30px;
}

.modal-body h3 {
  margin-bottom: 20px;
}

.modal-body img {
  margin: 10px 0;
}

.modal-body img.pull-left {
  margin: 10px 25px 10px 0;
  max-width: 286px;
}

.modal-body img.pull-right {
  margin: 10px 0 10px 25px;
  max-width: 286px;
}

.modal-body hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 480px) {
  .modal-body img.pull-left {
    margin: 10px 0;
    max-width: 100%;
  }

  .modal-body img.pull-right {
    margin: 10px 0;
    max-width: 100%;
  }
}

/* ==================================================
	Contact
====================================================*/

/* unstyled */

ul.unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.unstyled li {
  padding: 5px 0;
}

ul.unstyled i {
  margin-right: 20px;
}

/* Social Link */

ul.social-link {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.social-link li {
  display: inline-block;
  padding: 10px 20px 10px 0;
}

ul.social-link li a {
  color: #fff;
  opacity: 0.5;
}

ul.social-link li a:hover {
  opacity: 1;
}

ul.social-link li a i {
  font-size: 30px;
}

.mini-contact {
  font-size: 20px;
}

.mini-contact dt {
  margin-right: 6px;
  font-weight: 500;
}

/* ==================================================
	Footer
====================================================*/

#footer {
  padding-top: 30px;
}

.u-hiddenVisually {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.address {
  margin: 8px 0;
}

.address-item,
.contact-item {
  display: block;
}

.contact {
  margin-top: 20px;
  font-size: 18px;
}

.img-main {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
  border-radius: 12px;
}

.text-bold {
  font-weight: bold;
}

.contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.social-link:hover {
  text-decoration: none;
  color: #fff;
}

.social-text {
  margin-left: 15px;
  padding: 6px 12px;
  display: block;
  background-color: #6f7fb3;
  border-radius: 6px;
  font-weight: bold;
}

.line-through {
  text-decoration: line-through;
}
